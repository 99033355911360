import Redirect from "@pheaa/public-websites/dist/components/Redirect/Redirect";
import React, { lazy } from "react";

const
	viewComponents = {
		PageNotFound: lazy(() => import("../views/404/index")),
		HomePage: lazy(() => import("../views/App/App")),
		AboutUs: lazy(() => import("../views/about-us/index")),
		SiteMap: lazy(() => import("../views/site-map/index")),
		StudentAidLanding: lazy(() => import("../views/borrowers/student-aid-101/index")),
		TroublePayingLanding: lazy(() => import("../views/borrowers/trouble-paying/index")),
		ChangeYourDueDate: lazy(() => import("../views/borrowers/trouble-paying/change-your-due-date")),
		ReduceYourMonthlyPayments: lazy(() => import("../views/borrowers/trouble-paying/reduce-your-monthly-payments")),
		Bankruptcy: lazy(() => import("../views/borrowers/trouble-paying/bankruptcy")),
		Default: lazy(() => import("../views/borrowers/trouble-paying/default")),
		PostponePayments: lazy(() => import("../views/borrowers/trouble-paying/postpone-payments")),
		LoanDischargeAndLoanForgiveness: lazy(() => import("../views/borrowers/trouble-paying/loan-discharge-and-loan-forgiveness")),
		BasicsOfLoans: lazy(() => import("../views/borrowers/student-aid-101/the-basics-of-loans")),
		GrantsScholarshipsLoans: lazy(() => import("../views/borrowers/student-aid-101/grants-scholarships-and-loans")),
		StudentLoanQuiz: lazy(() => import("../views/borrowers/student-aid-101/student-loan-quiz")),
		TypesOfLoans: lazy(() => import("../views/borrowers/student-aid-101/types-of-loans")),
		LanguageOfLoans: lazy(() => import("../views/borrowers/student-aid-101/the-language-of-loans")),
		YourResponsibilities: lazy(() => import("../views/borrowers/student-aid-101/your-responsibilities")),
		FinancialAidOffer: lazy(() => import("../views/borrowers/student-aid-101/your-financial-aid-offer")),
		HowToApplyForALoan: lazy(() => import("../views/borrowers/student-aid-101/how-to-apply-for-a-federal-loan")),
		WhosWho: lazy(() => import("../views/borrowers/student-aid-101/whos-who")),
		Borrowers: lazy(() => import("../views/borrowers/index")),
		StudentAidTimeline: lazy(() => import("../views/borrowers/student-aid-timeline")),
		DebtReliefOrganizations: lazy(() => import("../views/borrowers/student-aid-101/beware-of-debt-relief-organizations")),
		ExitCounseling: lazy(() => import("../views/borrowers/student-aid-101/exit-counseling")),
		Interest: lazy(() => import("../views/borrowers/student-aid-101/about-interest")),
		HiddenTruths: lazy(() => import("../views/borrowers/student-aid-101/the-hidden-truths-of-student-loans")),
		ManagingMoneyLanding: lazy(() => import("../views/borrowers/managing-money/index")),
		CreditCards: lazy(() => import("../views/borrowers/managing-money/credit-cards")),
		HowToBudget: lazy(() => import("../views/borrowers/managing-money/how-to-budget")),
		HowToSave: lazy(() => import("../views/borrowers/managing-money/how-to-save")),
		KnowYourCreditScore: lazy(() => import("../views/borrowers/managing-money/know-your-credit-score")),
		MoreEarnMoreLearn: lazy(() => import("../views/borrowers/managing-money/more-learn-more-earn")),
		WaysToSaveGettingStarted: lazy(() => import("../views/borrowers/managing-money/saving/index")),
		ReduceFoodBill: lazy(() => import("../views/borrowers/managing-money/saving/reduce-food-bill")),
		ReduceHouseholdExpenses: lazy(() => import("../views/borrowers/managing-money/saving/reduce-household-expenses")),
		ReduceHousingBudget: lazy(() => import("../views/borrowers/managing-money/saving/reduce-housing-budget")),
		ReduceMiscExpenses: lazy(() => import("../views/borrowers/managing-money/saving/reduce-misc-expenses")),
		ReduceTransportationCosts: lazy(() => import("../views/borrowers/managing-money/saving/reduce-transportation-costs")),
		GraduateStudentsLanding: lazy(() => import("../views/borrowers/graduate-students/index")),
		HowToPay: lazy(() => import("../views/borrowers/graduate-students/how-to-pay")),
		GradStudentsAndLoans: lazy(() => import("../views/borrowers/graduate-students/grad-students-and-loans")),
		NontraditionalStudentsLanding: lazy(() => import("../views/borrowers/nontraditional/index")),
		DecisionToReturn: lazy(() => import("../views/borrowers/nontraditional/decision-to-return")),
		CostOfGoingBack: lazy(() => import("../views/borrowers/nontraditional/cost-of-going-back")),
		NontraditionalUndergrad: lazy(() => import("../views/borrowers/nontraditional/nontraditional-undergrad")),
		PersonalStoriesLanding: lazy(() => import("../views/borrowers/personal-stories/index")),
		Joe: lazy(() => import("../views/borrowers/personal-stories/joe")),
		Christine: lazy(() => import("../views/borrowers/personal-stories/christine")),
		Marc: lazy(() => import("../views/borrowers/personal-stories/marc")),
		MakingPayments: lazy(() => import("../views/borrowers/making-payments/index")),
		Benefits: lazy(() => import("../views/borrowers/making-payments/benefits-of-paying-interest")),
		GracePeriod: lazy(() => import("../views/borrowers/making-payments/grace-period")),
		WaysToPay: lazy(() => import("../views/borrowers/making-payments/ways-to-pay")),
		RepaymentPlans: lazy(() => import("../views/borrowers/making-payments/repayment-plans")),
		RepaymentQA: lazy(() => import("../views/borrowers/making-payments/repayment-quiz")),
		LoanConsolidation: lazy(() => import("../views/borrowers/making-payments/loan-consolidation")),
		LoanPayoff: lazy(() => import("../views/borrowers/making-payments/loan-payoff")),
		ParentsLanding: lazy(() => import("../views/parents/index")),
		BeforeCollege: lazy(() => import("../views/parents/before-college")),
		DuringCollege: lazy(() => import("../views/parents/during-college")),
		AfterCollege: lazy(() => import("../views/parents/after-college")),
		ParentsPersonalStories: lazy(() => import("../views/parents/personal-stories")),
		SchoolsLanding: lazy(() => import("../views/schools/index")),
		HowToUseYCDWI: lazy(() => import("../views/schools/how-to-use-youcandealwithit")),
		FinancialWellnessCurriculum: lazy(() => import("../views/schools/financial-wellness-curriculum")),
		DownloadableMaterials: lazy(() => import("../views/schools/downloadable-materials")),
		DefaultAversionRoster: lazy(() => import("../views/schools/default-aversion-roster")),
		CohortDefaultRate: lazy(() => import("../views/schools/cohort-default-rate")),
		EntranceCounseling: lazy(() => import("../views/schools/learning-modules/entrance-counseling")),
		LearningModulesLanding: lazy(() => import("../views/schools/learning-modules/index")),
		ExitCounselingLanding: lazy(() => import("../views/schools/learning-modules/exit-counseling/index")),
		CommandCentral: lazy(() => import("../views/schools/learning-modules/exit-counseling/command-central")),
		RetentionLanding: lazy(() => import("../views/schools/learning-modules/retention/index")),
		AdjustingQuiz: lazy(() => import("../views/schools/learning-modules/retention/adjusting-quiz")),
		SchoolsGrantsScholarships: lazy(() => import("../views/schools/learning-modules/retention/grants-scholarships")),
		Intervention: lazy(() => import("../views/schools/learning-modules/retention/intervention")),
		CalculatorsLanding: lazy(() => import("../views/calculators/index")),
		BudgetBuilder: lazy(() => import("../views/calculators/budget-builder")),
		BudgetCalculator: lazy(() => import("../views/calculators/budget-calculator")),
		CollegeCostCalculator: lazy(() => import("../views/calculators/college-cost-calculator")),
		DebtRepaymentCalculator: lazy(() => import("../views/calculators/debt-repayment-calculator")),
		GracePeriodCalculator: lazy(() => import("../views/calculators/grace-period-calculator")),
		InterestSavingsCalculator: lazy(() => import("../views/calculators/interest-savings-calculator")),
		LoanRepaymentCalculator: lazy(() => import("../views/calculators/loan-repayment-calculator")),
		OnlineResources: lazy(() => import("../views/online-resources/index")),
		PAStudentLoanExitCounseling: lazy(() => import("../views/exit-counseling/index"))
	},
	routeDataArray = [
		{
			path: "/",
			element: <viewComponents.HomePage />
		},
		{
			path: "budgetbuilder",
			element: <Redirect location="/calculators/budget-builder" />
		},
		{
			path: "learn-earn",
			element: <Redirect location="/borrowers/managing-money/more-learn-more-earn" />
		},
		{
			path: "postpone",
			element: <Redirect location="/borrowers/trouble-paying/postpone-payments" />
		},
		{
			path: "interest",
			element: <Redirect location="/borrowers/student-aid-101/about-interest" />
		},
		{
			path: "default",
			element: <Redirect location="/borrowers/trouble-paying/default" />
		},
		{
			path: "bankruptcy",
			element: <Redirect location="/borrowers/trouble-paying/bankruptcy" />
		},
		{
			path: "BeforeYouGo",
			element: <Redirect location={"/docs/FederalLoanServicerTracking.pdf"} />
		},
		{
			label: "About Us",
			path: "about-us",
			children: [
				{
					index: true,
					element: <viewComponents.AboutUs />
				}
			]
		},
		{
			label: "Site Map",
			path: "site-map",
			children: [
				{
					index: true,
					element: <viewComponents.SiteMap />
				}
			]
		},
		{
			label: "Borrowers",
			path: "borrowers",
			children: [
				{
					index: true,
					element: <viewComponents.Borrowers />
				},
				{
					path: "student-aid-timeline",
					label: "Student Aid Timeline",
					children: [
						{
							index: true,
							element: <viewComponents.StudentAidTimeline />
						}
					]
				},
				{
					path: "student-aid-101",
					label: "Student Aid 101",
					children: [
						{
							index: true,
							element: <viewComponents.StudentAidLanding />
						},
						{
							path: "grants-scholarships-and-loans",
							label: "Grants, Scholarships, and Loans",
							children: [
								{
									index: true,
									element: <viewComponents.GrantsScholarshipsLoans />
								}
							]
						},
						{
							path: "your-financial-aid-offer",
							label: "Your Financial Aid Offer",
							children: [
								{
									index: true,
									element: <viewComponents.FinancialAidOffer />
								}
							]
						},
						{
							path: "types-of-loans",
							label: "Types of Loans",
							children: [
								{
									index: true,
									element: <viewComponents.TypesOfLoans />
								}
							]
						},
						{
							path: "the-basics-of-loans",
							label: "The Basics of Loans",
							children: [
								{
									index: true,
									element: <viewComponents.BasicsOfLoans />
								}
							]
						},
						{
							path: "the-language-of-loans",
							label: "The Language of Loans",
							children: [
								{
									index: true,
									element: <viewComponents.LanguageOfLoans />
								}
							]
						},
						{
							path: "how-to-apply-for-a-federal-loan",
							label: "How to Apply for a Federal Loan",
							children: [
								{
									index: true,
									element: <viewComponents.HowToApplyForALoan />
								}
							]
						},
						{
							path: "about-interest",
							label: "About Interest",
							children: [
								{
									index: true,
									element: <viewComponents.Interest />
								}
							]
						},
						{
							path: "your-responsibilities",
							label: "Your Responsibilities",
							children: [
								{
									index: true,
									element: <viewComponents.YourResponsibilities />
								}
							]
						},
						{
							path: "whos-who",
							label: "Who's Who",
							children: [
								{
									index: true,
									element: <viewComponents.WhosWho />
								}
							]
						},
						{
							path: "student-loan-quiz",
							label: "Student Loan Quiz",
							children: [
								{
									index: true,
									element: <viewComponents.StudentLoanQuiz />
								}
							]
						},
						{
							path: "the-hidden-truths-of-student-loans",
							label: "The Hidden Truths of Student Loans",
							children: [
								{
									index: true,
									element: <viewComponents.HiddenTruths />
								}
							]
						},
						{
							path: "exit-counseling",
							label: "Exit Counseling",
							children: [
								{
									index: true,
									element: <viewComponents.ExitCounseling />
								}
							]
						},
						{
							path: "beware-of-debt-relief-organizations",
							label: "Beware of \"Debt Relief\" Organizations",
							children: [
								{
									index: true,
									element: <viewComponents.DebtReliefOrganizations />
								}
							]
						}
					]
				},
				{
					path: "trouble-paying",
					label: "Trouble Paying",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingLanding />
						},
						{
							path: "change-your-due-date",
							label: "Change Your Due Date",
							children: [
								{
									index: true,
									element: <viewComponents.ChangeYourDueDate />
								}
							]
						},
						{
							path: "reduce-your-monthly-payments",
							label: "Reduce Your Monthly Payments",
							children: [
								{
									index: true,

									element: <viewComponents.ReduceYourMonthlyPayments />
								}
							]
						},
						{
							path: "postpone-payments",
							label: "Postpone Payments",
							children: [
								{
									index: true,

									element: <viewComponents.PostponePayments />
								}
							]
						},
						{
							path: "loan-discharge-and-loan-forgiveness",
							label: "Loan Discharge and Loan Forgiveness",
							children: [
								{
									index: true,

									element: <viewComponents.LoanDischargeAndLoanForgiveness />
								}
							]
						},
						{
							path: "default",
							label: "Default",
							children: [
								{
									index: true,

									element: <viewComponents.Default />
								}
							]
						},
						{
							path: "bankruptcy",
							label: "Bankruptcy",
							children: [
								{
									index: true,
									element: <viewComponents.Bankruptcy />
								}
							]
						}
					]
				},
				{
					path: "making-payments",
					label: "Making Payments",
					children: [
						{
							index: true,
							element: <viewComponents.MakingPayments />
						},
						{
							label: "Benefits of Paying Interest",
							path: "benefits-of-paying-interest",
							children: [
								{
									index: true,
									element: <viewComponents.Benefits />
								}
							]
						},
						{
							label: "Grace Period",
							path: "grace-period",
							children: [
								{
									index: true,
									element: <viewComponents.GracePeriod />
								}
							]
						},
						{
							label: "Ways To Pay",
							path: "ways-to-pay",
							children: [
								{
									index: true,
									element: <viewComponents.WaysToPay />
								}
							]
						},
						{
							label: "Repayment Plans",
							path: "repayment-plans",
							children: [
								{
									index: true,
									element: <viewComponents.RepaymentPlans />
								}
							]
						},
						{
							label: "Repayment Quiz",
							path: "repayment-quiz",
							children: [
								{
									index: true,
									element: <viewComponents.RepaymentQA />
								}
							]
						},
						{
							label: "Loan Consolidation",
							path: "loan-consolidation",
							children: [
								{
									index: true,
									element: <viewComponents.LoanConsolidation />
								}
							]
						},
						{
							label: "Loan Payoff",
							path: "loan-payoff",
							children: [
								{
									index: true,
									element: <viewComponents.LoanPayoff />
								}
							]
						}
					]
				},
				{
					path: "managing-money",
					label: "Managing Money",
					children: [
						{
							index: true,
							element: <viewComponents.ManagingMoneyLanding />
						},
						{
							path: "more-learn-more-earn",
							label: "The More You Learn, the More You Earn",
							children: [
								{
									index: true,
									element: <viewComponents.MoreEarnMoreLearn />
								}
							]
						},
						{
							path: "how-to-budget",
							label: "How to Budget",
							children: [
								{
									index: true,
									element: <viewComponents.HowToBudget />
								}
							]
						},
						{
							path: "how-to-save",
							label: "How to Save",
							children: [
								{
									index: true,
									element: <viewComponents.HowToSave />
								}
							]
						},
						{
							path: "know-your-credit-score",
							label: "Know Your Credit Score",
							children: [
								{
									index: true,
									element: <viewComponents.KnowYourCreditScore />
								}
							]
						},
						{
							path: "credit-cards",
							label: "Credit Cards",
							children: [
								{
									index: true,
									element: <viewComponents.CreditCards />
								}
							]
						},
						{
							path: "saving",
							label: "Ways to Save - Getting Started",
							children: [
								{
									index: true,
									element: <viewComponents.WaysToSaveGettingStarted />
								},
								{
									path: "reduce-housing-budget",
									label: "Ways to Reduce Your Housing Budget",
									children: [
										{
											index: true,
											element: <viewComponents.ReduceHousingBudget />
										}
									]
								},
								{
									path: "reduce-household-expenses",
									label: "Ways to Reduce Your Household Expenses",
									children: [
										{
											index: true,
											element: <viewComponents.ReduceHouseholdExpenses />
										}
									]
								},
								{
									path: "reduce-food-bill",
									label: "Ways to Reduce Your Food Bill",
									children: [
										{
											index: true,
											element: <viewComponents.ReduceFoodBill />
										}
									]
								},
								{
									path: "reduce-transportation-costs",
									label: "Ways to Reduce Your Transportation Costs",
									children: [
										{
											index: true,
											element: <viewComponents.ReduceTransportationCosts />
										}
									]
								},
								{
									path: "reduce-misc-expenses",
									label: "Ways to Reduce Your Miscellaneous Expenses",
									children: [
										{
											index: true,
											element: <viewComponents.ReduceMiscExpenses />
										}
									]
								}
							]
						}
					]
				},
				{
					path: "personal-stories",
					label: "Personal Stories",
					children: [
						{
							index: true,
							element: <viewComponents.PersonalStoriesLanding />
						},
						{
							path: "joe",
							label: "Meet Joe",
							children: [
								{
									index: true,
									element: <viewComponents.Joe />
								}
							]
						},
						{
							path: "christine",
							label: "Meet Christine",
							children: [
								{
									index: true,
									element: <viewComponents.Christine />
								}
							]
						},
						{
							path: "marc",
							label: "Meet Marc",
							children: [
								{
									index: true,
									element: <viewComponents.Marc />
								}
							]
						}
					]
				},
				{
					path: "graduates",
					label: "Graduate and Professional Students",
					children: [
						{
							index: true,
							element: <viewComponents.GraduateStudentsLanding />
						},
						{
							path: "how-to-pay",
							label: "How to Pay for Graduate School",
							children: [
								{
									index: true,
									element: <viewComponents.HowToPay />
								}
							]
						},
						{
							path: "grad-students-and-loans",
							label: "Graduate Students and Loans",
							children: [
								{
									index: true,
									element: <viewComponents.GradStudentsAndLoans />
								}
							]
						}
					]
				},
				{
					path: "nontraditional",
					label: "Nontraditional Students",
					children: [
						{
							index: true,
							element: <viewComponents.NontraditionalStudentsLanding />
						},
						{
							path: "nontraditional-undergrad",
							label: "What Is a Nontraditional Student?",
							children: [
								{
									index: true,
									element: <viewComponents.NontraditionalUndergrad />
								}
							]
						},
						{
							path: "cost-of-going-back",
							label: "The Cost of Going Back to School",
							children: [
								{
									index: true,
									element: <viewComponents.CostOfGoingBack />
								}
							]
						},
						{
							path: "decision-to-return",
							label: "The Decision to Return to School",
							children: [
								{
									index: true,
									element: <viewComponents.DecisionToReturn />
								}
							]
						}
					]
				}
			]
		},
		{
			label: "Parents",
			path: "parents",
			children: [
				{
					index: true,
					element: <viewComponents.ParentsLanding />
				},
				{
					path: "before-college",
					label: "Before College",
					children: [
						{
							index: true,
							element: <viewComponents.BeforeCollege />
						}
					]
				},
				{
					path: "during-college",
					label: "During College",
					children: [
						{
							index: true,
							element: <viewComponents.DuringCollege />
						}
					]
				},
				{
					path: "after-college",
					label: "After College",
					children: [
						{
							index: true,
							element: <viewComponents.AfterCollege />
						}
					]
				},
				{
					path: "personal-stories",
					label: "Meet Sandra",
					children: [
						{
							index: true,
							element: <viewComponents.ParentsPersonalStories />
						}
					]
				}
			]
		},
		{
			label: "Schools",
			path: "schools",
			children: [
				{
					index: true,
					element: <viewComponents.SchoolsLanding />
				},
				{
					path: "financial-wellness-curriculum",
					label: "Financial Wellness Curriculum",
					children: [
						{
							index: true,
							element: <viewComponents.FinancialWellnessCurriculum />
						}
					]
				},
				{
					path: "downloadablematerials",
					label: "Downloadable Materials",
					children: [
						{
							index: true,
							element: <viewComponents.DownloadableMaterials />
						}
					]
				},
				{
					path: "cohort-default-rate",
					label: "Cohort Default Rate",
					children: [
						{
							index: true,
							element: <viewComponents.CohortDefaultRate />
						}
					]
				},
				{
					path: "default-aversion-roster",
					label: "Default Aversion Roster",
					children: [
						{
							index: true,
							element: <viewComponents.DefaultAversionRoster />
						}
					]
				},
				{
					path: "how-to-use-youcandealwithit",
					label: "How to use YouCanDealWithIt",
					children: [
						{
							index: true,
							element: <viewComponents.HowToUseYCDWI />
						}
					]
				},
				{
					path: "learning-modules",
					label: "Learning Modules",
					children: [
						{
							index: true,
							element: <viewComponents.LearningModulesLanding />
						},
						{
							path: "entrance-counseling",
							label: "Entrance Counseling",
							children: [
								{
									index: true,
									element: <viewComponents.EntranceCounseling />
								}
							]
						},
						{
							path: "retention",
							label: "Retention",
							children: [
								{
									index: true,
									element: <viewComponents.RetentionLanding />
								},
								{
									path: "adjusting-quiz",
									label: "Adjusting to School Checklist",
									children: [
										{
											index: true,
											element: <viewComponents.AdjustingQuiz />
										}
									]
								},
								{
									path: "grants-scholarships",
									label: "Grants Scholarships",
									children: [
										{
											index: true,
											element: <viewComponents.SchoolsGrantsScholarships />
										}
									]
								},
								{
									path: "intervention",
									label: "Intervention",
									children: [
										{
											index: true,
											element: <viewComponents.Intervention />
										}
									]
								}
							]
						},
						{
							path: "exit-counseling",
							label: "Exit Counseling",
							children: [
								{
									index: true,
									element: <viewComponents.ExitCounselingLanding />
								},
								{
									path: "command-central",
									label: "Command Central",
									children: [
										{
											index: true,
											element: <viewComponents.CommandCentral />
										}
									]
								}
							]
						}
					]
				}
			]
		},
		{
			label: "Calculators",
			path: "calculators",
			children: [
				{
					index: true,
					element: <viewComponents.CalculatorsLanding />
				},
				{
					path: "budget-builder",
					label: "Budget Builder",
					children: [
						{
							index: true,
							element: <viewComponents.BudgetBuilder />
						}
					]
				},
				{
					path: "budget-calculator",
					label: "Budget Calculator",
					children: [
						{
							index: true,
							element: <viewComponents.BudgetCalculator />
						}
					]
				},
				{
					path: "college-cost-calculator",
					label: "College Cost Calculator",
					children: [
						{
							index: true,
							element: <viewComponents.CollegeCostCalculator />
						}
					]
				},
				{
					path: "debt-repayment-calculator",
					label: "Debt Repayment Calculator",
					children: [
						{
							index: true,
							element: <viewComponents.DebtRepaymentCalculator />
						}
					]
				},
				{
					path: "grace-period-calculator",
					label: "Grace Period Calculator",
					children: [
						{
							index: true,
							element: <viewComponents.GracePeriodCalculator />
						}
					]
				},
				{
					path: "interest-savings-calculator",
					label: "Interest Savings Calculator",
					children: [
						{
							index: true,
							element: <viewComponents.InterestSavingsCalculator />
						}
					]
				},
				{
					path: "loan-repayment-calculator",
					label: "Loan Repayment Calculator",
					children: [
						{
							index: true,
							element: <viewComponents.LoanRepaymentCalculator />
						}
					]
				}
			]
		},
		{
			label: "PA Student Loan Exit Counseling",
			path: "exit-counseling",
			children: [
				{
					index: true,
					element: <viewComponents.PAStudentLoanExitCounseling />
				}
			]
		},
		{
			label: "Online Resources",
			path: "online-resources",
			children: [
				{
					index: true,
					element: <viewComponents.OnlineResources />
				}
			]
		},
		{
			path: "*",
			element: <viewComponents.PageNotFound />
		}
	];


export { routeDataArray, viewComponents };